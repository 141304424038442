import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Floor from "../components/floors/floor-1/floor"

const GroundFloorPage = () => (
  <Layout pageTitle={false} sideBar={false}>
    <SEO title="Office Rentals in Bristol - Cotham, Ground Floor" />
    <Floor/>
  </Layout>
)

export default GroundFloorPage
 